'use client';

import { Button } from '@nextui-org/button';
import '@/styles/globals.css';
import Error from 'next/error';
import React from 'react';

export default function NotFound() {
  return (
    <html>
      <body>
        <Error withDarkMode statusCode={404} />
        <Button
          className="absolute left-1/2 top-[59%] -translate-x-1/2 -translate-y-1/2 font-bold"
          variant="solid"
          onClick={() => history.back()}
        >
          Go back
        </Button>
      </body>
    </html>
  );
}
